import React, { useState, useEffect } from 'react';
import { getGlobal } from 'reactn';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DatePicker from 'antd/lib/date-picker';
import moment from 'moment';
import Button from '../Button';

const StyledDatePicker = styled(DatePicker)``;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  > * {
    margin: 0 0.5em;
  }
  > *:first-child {
    margin-left: 0;
  }
  > *:last-child {
    margin-right: 0;
  }
`;

const CustomWeekPicker = ({ endDate: endDateProp, onDateChange, loading = false }) => {
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    setEndDate(endDateProp);
  }, [endDateProp]);

  const handleChangeOfWeekDate = momentDate => {
    const { config } = getGlobal();
    const weekEndDay = config['week-end-day'];
    const dayMapping = { sun: 0, mon: 1, tue: 2, wed: 3, thu: 4, fri: 5, sat: 6 };
    const newEndDate = momentDate.startOf('isoWeek').day(dayMapping[weekEndDay]);
    if (onDateChange) {
      onDateChange(newEndDate.format('YYYYMMDD'));
    }
  };

  const goToNextWeek = oldEndDate => {
    const newEndDate = moment(oldEndDate).add(1, 'week');
    onDateChange(newEndDate.toDate());
  };

  const goToPrevWeek = oldEndDate => {
    const newEndDate = moment(oldEndDate).subtract(1, 'week');
    onDateChange(newEndDate.toDate());
  };

  const goToCurrentWeek = () => {
    const timesheetEndDay = 'Fri';
    const dayMapping = { Sun: 0, Mon: 1, Tue: 2, Wed: 3, Thu: 4, Fri: 5, Sat: 6 };
    const newEndDate = moment().startOf('isoWeek').day(dayMapping[timesheetEndDay]);
    onDateChange(newEndDate.toDate());
  };

  const endDateMoment = moment(endDate);
  return (
    <Wrapper className="flex-wrap gap-2">
      <div className="flex flex-row gap-2">
        <Button disabled={loading} icon="left" onClick={() => goToPrevWeek(endDateMoment)} />
        <Button disabled={loading} onClick={() => goToCurrentWeek(endDateMoment)}>
          Current Week
        </Button>
        <Button disabled={loading} icon="right" onClick={() => goToNextWeek(endDateMoment)} />
      </div>
      <StyledDatePicker
        disabled={loading}
        allowClear={false}
        format="MM/DD/YYYY"
        value={endDateMoment}
        onChange={handleChangeOfWeekDate}
        placeholder="Select Week"
      />
    </Wrapper>
  );
};

CustomWeekPicker.propTypes = {
  endDate: PropTypes.string,
  onDateChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default CustomWeekPicker;
