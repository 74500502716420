import { useState } from 'react';
import styled from 'styled-components';
import { useGlobal } from 'reactn';
import Button from '../../common/components/Button';
import Tooltip from '../../common/components/Tooltip';
import Cascader from '../../common/components/Cascader';
import { GET_USER_TIMESHEET } from '../queries';
import { ADD_TIMESHEET_LINEITEM } from '../mutations';
import PrefillTimesheetButton from './PrefillTimesheetButton';
import client from '../../../api/graphql/client';
import { useLocation } from 'react-router-dom';
import TaskSelector from '../../common/components/TaskSelector';
import { getWeekDays } from '../../common';

const Col = styled.td`
  padding: 1em;
  font-weight: 600;
  width: ${props => (props.width ? props.width : 'initial')};
  text-align: ${props => (props.align ? props.align : 'initial')};
  &:first-child {
    padding-left: 0;
  }
`;

const Hours = styled.span`
  ${props => props.highlightColor && `color: ${props.highlightColor}`};
  font-weight: bold;
`;

const Wrapper = styled.tr`
  border-top: 1px solid #f1f1f1;
`;

export const constructOptions = (projects, selectedDeliverableIds) =>
  projects.map(project => {
    const milestones = project.deliverables
      .reduce((acc, cur) => {
        const milestone = acc.find(milestone => milestone.label === cur.milestoneName);
        if (milestone) {
          if (!milestone.children) {
            milestone.children = [
              {
                label: cur.deliverableName,
                value: cur._id,
                disabled: cur.disabled || selectedDeliverableIds.includes(cur._id),
              },
            ];
          } else {
            const existingDeliverable = milestone.children.find(
              child => child.label === cur.deliverableName,
            );
            if (!existingDeliverable) {
              milestone.children.push({
                label: cur.deliverableName,
                value: cur._id,
                disabled: cur.disabled || selectedDeliverableIds.includes(cur._id),
              });
            }
          }
          return acc;
        }

        return [
          ...acc,
          {
            label: cur.milestoneName,
            value: cur.milestoneName,
            children: [
              {
                value: cur._id,
                label: cur.deliverableName,
                disabled: cur.disabled || selectedDeliverableIds.includes(cur._id),
              },
            ],
          },
        ];
      }, [])
      .map(milestone => ({
        ...milestone,
        children: milestone.children.filter(d => d.label !== milestone.label),
      }));

    const label = project.client?.shortName
      ? `${project.projectCode ? `[${project.projectCode}]` : ``} ${project.client.shortName} / ${
          project.name
        }`
      : project.name;

    return {
      label,
      value: project._id,
      children: milestones,
    };
  });

const TimesheetGridRowAddNew = ({
  timesheetId,
  data,
  loading,
  dailyTotals,
  isLocked,
  endDate,
  lineItems,
  selectedDeliverableIds,
  userId,
  onSubmit = () => {},
  onSubmitEnd = () => {},
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { pathname } = useLocation();
  const [{ user: stateUser }] = useGlobal();
  const user = userId ? { _id: userId } : stateUser;
  const useNewTaskSelector = stateUser?.settings?.useNewTaskSelector || false;
  const recentProjects = user?.recentProjects || [];

  const handleClick = async item => {
    const deliverableId = item[item.length - 1];
    const projectId = item[0];
    setIsSubmitting(true);
    await client.mutate({
      mutation: ADD_TIMESHEET_LINEITEM,
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_USER_TIMESHEET,
          variables: {
            endDate: endDate,
            user: user._id,
          },
        },
      ],
      variables: {
        deliverableId,
        timesheetId,
        projectId,
        user: user._id,
      },
    });
    setIsSubmitting(false);
  };

  const renderTotalForWeekDay = (hours, index) => {
    let highlightColor;
    if (hours > 8) {
      highlightColor = 'orange';
    }
    if (hours > 12) {
      highlightColor = 'red';
    }
    return (
      <Col align="center" key={index}>
        <Hours highlightColor={highlightColor}>{hours === 0 ? '' : hours}</Hours>
      </Col>
    );
  };

  const renderTotals = () => {
    const days = getWeekDays();
    return days.map((day, index) => {
      return renderTotalForWeekDay(dailyTotals[day], index);
    });
  };

  const sortedProjects = data?.projects
    ? [...data.projects].sort((a, b) => {
        const isRecentA = recentProjects.includes(a._id);
        const isRecentB = recentProjects.includes(b._id);
        return isRecentB - isRecentA;
      })
    : [];

  let options =
    data && data.projects ? constructOptions(sortedProjects, selectedDeliverableIds) : [];

  return (
    <Wrapper>
      {isLocked ? (
        <Col />
      ) : (
        <Col>
          {options.length === 0 && !loading ? (
            <Tooltip trigger="hover" placement="right" title="You are not part of any project">
              <Button disabled icon="plus">
                Select Task
              </Button>
            </Tooltip>
          ) : (
            <div className="flex flex-row gap-2 items-center">
              {useNewTaskSelector ? (
                <TaskSelector
                  type="primary"
                  options={options}
                  onSelect={selected => handleClick([selected.project, undefined, selected.task])}
                />
              ) : (
                <Cascader
                  notFoundContent="No results"
                  expandTrigger="click"
                  options={options}
                  onChange={handleClick}
                  popupPlacement="topLeft"
                  value={null}
                  size="large"
                  placeholder="Search for task"
                  showSearch={{
                    matchInputWidth: false,
                    filter: (inputValue, path) => {
                      return path.some(option => {
                        if (option.label) {
                          return (
                            option.label.toLowerCase().indexOf(inputValue?.toLowerCase() || '') > -1
                          );
                        }
                        return null;
                      });
                    },
                  }}
                  onPopupVisibleChange={open => {
                    setIsOpen(open);
                  }}
                >
                  {!isOpen && (
                    <Button
                      ghost
                      type="primary"
                      size="large"
                      id="selectDeliverableBtn"
                      loading={loading || data.loading || isSubmitting}
                      icon="plus"
                    >
                      Select Task
                    </Button>
                  )}
                </Cascader>
              )}

              {!lineItems && !pathname.includes('manualTimesheets') && (
                <PrefillTimesheetButton
                  userId={user._id}
                  newTimesheetId={timesheetId}
                  endDate={endDate}
                  refetchQueries={[
                    {
                      query: GET_USER_TIMESHEET,
                      variables: {
                        endDate: endDate,
                        user: user._id,
                      },
                    },
                  ]}
                />
              )}
            </div>
          )}
        </Col>
      )}
      <Col align="center" />
      {renderTotals()}
    </Wrapper>
  );
};

export default TimesheetGridRowAddNew;
