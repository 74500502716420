import React, { useState } from 'react';
import { Button, Modal, Group, Checkbox } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useMutation } from '@apollo/react-hooks';
import { PREFILL_ADD_TIMESHEET_LINEITEM } from '../mutations';
import PrefillTimesheetPreview from './PrefillTimesheetPreview';

interface PrefillTimesheetProps {
    userId: string;
    newTimesheetId: string;
    refetchQueries: any[];
    endDate: string;
}

const PrefillTimesheet: React.FC<PrefillTimesheetProps> = ({ userId, newTimesheetId, refetchQueries, endDate }) => {
    const [opened, { open, close }] = useDisclosure(false);
    const [originalTimesheetId, setOriginalTimesheetId] = useState<string | null>(null);
    const [prefillTimesheet, { loading: updating }] = useMutation(PREFILL_ADD_TIMESHEET_LINEITEM);
    const [includeOptions, setIncludeOptions] = useState<string[]>([]);

    const handlePrefill = async () => {
        await prefillTimesheet({
            variables: {
                originalTimesheetId,
                newTimesheetId,
                includeOptions,
            },
            refetchQueries,
            awaitRefetchQueries: true,
        });
        close();
    };

    return (
        <div>
            <Modal opened={opened} onClose={close} size="100%" title="Choose a week to prefill from">
                <PrefillTimesheetPreview userId={userId} onTimesheetChange={setOriginalTimesheetId} endDate={endDate} />
                <div className="flex flex-col gap-2 md:flex-row justify-between">
                    <Checkbox.Group
                        defaultValue={[]}
                        description="Specify if you want to include the hours and comments for each task"
                        onChange={setIncludeOptions}
                    >
                        <Group mt="xs">
                            <Checkbox value="hours" label="Hours" />
                            <Checkbox value="comments" disabled={!includeOptions.includes('hours')} label="Comments" />
                        </Group>
                    </Checkbox.Group>
                    <div className="flex flex-row gap-2 justify-end md:items-end">
                        <Button variant="light" onClick={close} disabled={updating}>Cancel</Button>
                        <Button onClick={handlePrefill} loading={updating}>Prefill</Button>
                    </div>
                </div>
            </Modal>

            <Button
                variant="subtle" color="gray" size="xs"
                onClick={open}
                uppercase
            >
                Prefill
            </Button>
        </div>
    );
};

export default PrefillTimesheet;