import {
  Section,
  Heading,
  Table,
  Thead,
  Tr,
  Th,
  Cell,
  CellTitle,
  Tbody,
  Td,
} from './TimesheetReport';
const SummaryReport = ({ user, config, timesheet, myProjectIds, days, endDate, status }) => {
  const totals = days.reduce((acc, day, index) => {
    const dailyTasks = timesheet.week[day];

    dailyTasks.forEach(task => {
      if (!acc[task.project.name]) {
        acc = {
          ...acc,
          [task.project.name]: {},
        };
      }
      if (!acc[task.project.name][task.deliverable.deliverableName]) {
        acc[task.project.name] = {
          ...acc[task.project.name],
          [task.deliverable.deliverableName]: { hours: 0 },
        };
      }
      acc[task.project.name][task.deliverable.deliverableName] = {
        ...acc[task.project.name][task.deliverable.deliverableName],
        hours: acc[task.project.name][task.deliverable.deliverableName].hours + task.hours,
      };
    });
    return acc;
  }, {});

  return (
    <>
      <Section>
        <Heading>Summary by Deliverable</Heading>
      </Section>
      <Table>
        <Thead>
          <Tr height="50px">
            <Th colSpan="1" align="center">
              <Cell>
                <CellTitle>Project/Deliverable</CellTitle>
              </Cell>
            </Th>
            <Th colSpan="1" align="center">
              <Cell>
                <CellTitle>Hours per deliverable</CellTitle>
              </Cell>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {Object.entries(totals).map(([project, deliverables], index) => {
            let totalHoursPerProject = 0;
            return (
              <>
                <Tr dark key={index}>
                  <Td colSpan="2" align="center">
                    {project}
                  </Td>
                </Tr>
                {Object.entries(deliverables).map(([deliverable, info]) => {
                  totalHoursPerProject += info.hours;
                  return (
                    <>
                      <Tr>
                        <Td align="center">{deliverable}</Td>
                        <Td align="center">{info.hours}</Td>
                      </Tr>
                    </>
                  );
                })}
                <Tr>
                  <Td align="center" bold>
                    Total
                  </Td>
                  <Td align="center" bold>
                    {totalHoursPerProject}
                  </Td>
                </Tr>
              </>
            );
          })}
                <Tr style={{ backgroundColor: '#32547A', color: 'white' }}>
                  <Td align="center" bold>
                    Timesheet Total
                  </Td>
                  <Td align="center" bold>
                    {timesheet.totalHours}
                  </Td>
                </Tr>
        </Tbody>
      </Table>
    </>
  );
};

export default SummaryReport;
