import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Empty } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import TimesheetGrid from './TimesheetGrid';
import WeekPicker from '../../common/components/WeekPicker';
import TimesheetLoading from '../../timesheets/components/TimesheetLoading';
import { GET_USER_TIMESHEET } from '../queries';

interface PrefillTimesheetProps {
    onTimesheetChange: (timesheetId: string) => void;
    userId: string;
    endDate: string;
}

const PrefillTimesheet: React.FC<PrefillTimesheetProps> = ({ onTimesheetChange, userId, endDate: initialEndDate }) => {
    const [endDate, setEndDate] = useState(moment(initialEndDate).subtract(1, 'week').format('YYYYMMDD'));
    const { data, loading } = useQuery(GET_USER_TIMESHEET, {
        variables: {
            endDate: endDate,
            user: userId,
        },
    });

    const timesheet = data?.timesheet;

    useEffect(() => {
        if (timesheet) {
            onTimesheetChange(timesheet._id);
        }
    }, [timesheet, onTimesheetChange]);

    return (
        <div className="">
            <div className="flex flex-row justify-end">
                <WeekPicker onDateChange={(value) => {
                    setEndDate(moment(value).format('YYYYMMDD'));
                }} endDate={endDate} />
            </div>
            <div className="mt-4 overflow-x-auto">
                {loading ? <TimesheetLoading /> :
                    timesheet && <TimesheetGrid
                        className="min-w-[1000px]"
                        endDate={timesheet.endDate}
                        timesheetId={timesheet._id}
                        lineItems={timesheet.lineItems}
                        isLocked={true}
                        shouldAllowSubmit={() => false}
                        userId={userId}
                    />}
                {!loading && !timesheet && (
                    <div className="py-8">
                        <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description="No timesheet found for this week"
                        />
                    </div>
                )}
            </div>
        </div>

    );
};

export default PrefillTimesheet;