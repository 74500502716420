import React, { useGlobal } from 'reactn';
import moment from 'moment';
import { useHistory, useParams } from 'react-router-dom';
import Card from '../../common/components/Card';
import WeekPicker from '../../common/components/WeekPicker';
import ManualTimesheetContainer from './ManualTimesheetContainer';

const ManualTimesheetCard = props => {
  const { userId, endDate } = useParams();
  const history = useHistory();
  const [config] = useGlobal('config');

  React.useEffect(() => {
    const weekEndDay = config['week-end-day'];
    const dayMapping = { sun: 0, mon: 1, tue: 2, wed: 3, thu: 4, fri: 5, sat: 6 };

    if (!endDate) {
      const currentEndDate = moment().startOf('isoWeek').day(dayMapping[weekEndDay]);
      // if no endDate is provided, redirect to current week's endDate
      history.replace(
        `/finance/manualTimesheets/${userId}/${moment(currentEndDate).format('YYYYMMDD')}`,
      );
    } else {
      const currentDate = moment(endDate);
      const currentDayOfWeek = currentDate.day();
      const targetDayOfWeek = dayMapping[weekEndDay];

      // Check if the current day matches the weekEndDay
      if (currentDayOfWeek === targetDayOfWeek) {
        return; // Do nothing if it matches
      }

      // Calculate the days to add to get to the next weekEndDay
      let daysToAdd = (targetDayOfWeek - currentDayOfWeek + 7) % 7;
      if (daysToAdd === 0) {
        daysToAdd = 7; // Move to the next week if it's the same day
      }

      // Calculate the next end date
      const nextEndDate = currentDate.add(daysToAdd, 'days');
      history.replace(
        `/finance/manualTimesheets/${userId}/${moment(nextEndDate).format('YYYYMMDD')}`,
      );
    }
  }, [config, endDate, history, userId]);

  if (!endDate) {
    return null;
  }

  return (
    <Card
      title="Weekly Timesheet"
      padded={false}
      floating
      withShadow
      actionComponent={
        <WeekPicker
          endDate={endDate}
          onDateChange={endDate => {
            const endDateString = moment(endDate).format('YYYYMMDD');
            history.push(`/finance/manualTimesheets/${userId}/${endDateString}`);
          }}
        />
      }
    >
      <ManualTimesheetContainer endDate={endDate} user={userId} />
    </Card>
  );
};

export default ManualTimesheetCard;
