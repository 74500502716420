import { useState, useEffect } from 'react';
import { Select } from 'antd';
import Card from '../../common/components/Card';
import { Switch, Route, useHistory, useParams } from 'react-router-dom';
import ManualTimesheetCard from './ManualTimesheetCard';

export const ListUsers = props => {
  let history = useHistory();
  let { userId, endDate } = useParams();
  const [selectedUserId, setSelectedUser] = useState(userId);
  const { users } = props;
  const { Option } = Select;

  const onChange = value => {
    setSelectedUser(value);
    history.push(endDate ? `/finance/manualTimesheets/${value}/${endDate}` : `/finance/manualTimesheets/${value}`); // Navigate using React Router
  };
  
  useEffect(() => {
    if (userId) {
      setSelectedUser(userId);
    }
  }, [userId])

  return (
    <>
      <Card floating padded={true} title="Add Timesheet for Other Users">
        <Select
          showSearch
          style={{ width: 400 }}
          placeholder="Select a User"
          optionFilterProp="children"
          value={selectedUserId}
          onChange={onChange}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {users &&
            users.map(item => {
              let firstName = item.firstName ? item.firstName : '';
              let lastName = item.lastName ? item.lastName : '';
              let email = item.emails.length ? item.emails[0].address : '';
              return (
                <Option value={item._id} key={item._id}>
                  {firstName + ' ' + lastName + ' - ' + email}
                </Option>
              );
            })}
        </Select>
      </Card>
      <Switch>
        <Route
          path="/finance/manualTimesheets/:userId/:endDate"
          render={() => <ManualTimesheetCard />}
        />
        <Route path="/finance/manualTimesheets/:userId" render={() => <ManualTimesheetCard />} />
      </Switch>
    </>
  );
};

export default ListUsers;
