import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { AgGridReact, AgGridColumn } from '@ag-grid-community/react';
import { AllCommunityModules } from '@ag-grid-community/all-modules';

const Wrapper = styled.div`
  height: 700px;
  width: 100%;
  div.ag-root {
    border: 0;
  }
  div.ag-row:last-child {
    border-bottom: 0;
  }
`;

class TimesheetDailySnapshot extends React.Component {
  gridApi = undefined;

  onGridReady = params => {
    this.gridApi = params.api;
    const { onExportReady } = this.props;
    onExportReady({ getExportFunc: () => this.export });
  };

  export = () => {
    const { endDate } = this.props;
    const params = {
      fileName: `TimesheetSnapshot-WeekEnding(${endDate})-ExportedOn(${moment(
        new Date(),
      ).format('YYYYMMDD-hhmm')}).csv`,
    };
    this.gridApi.exportDataAsCsv(params);
  };

  render() {
    const { rowData } = this.props;
    return (
      <Wrapper className="ag-theme-balham">
        <AgGridReact
          enableCellTextSelection
          modules={AllCommunityModules}
          onGridReady={this.onGridReady}
          rowData={rowData}
          defaultColDef={{
            sortable: true,
            resizable: true,
            filter: true,
          }}
        >
          <AgGridColumn field="endDate" width={125} />
          <AgGridColumn field="status" width={175} />
          <AgGridColumn field="lastName" />
          <AgGridColumn field="firstName" />
          <AgGridColumn field="payrollId" headerName="Payroll ID" />
          <AgGridColumn field="email" />
          <AgGridColumn field="clientName" width={150} />
          <AgGridColumn field="project" />
          {/* <AgGridColumn field="bu" headerName="BU - IS"/> */}
          <AgGridColumn field="projectCode" />
          <AgGridColumn field="milestone" />
          <AgGridColumn field="deliverable" />
          <AgGridColumn field="taskdate" width={110} />
          <AgGridColumn field="hours" width={110} />
          <AgGridColumn field="role" />
          <AgGridColumn field="rate" />
          <AgGridColumn field="name" />
          <AgGridColumn field="totalCost" />
          <AgGridColumn field="supervisor" />
          <AgGridColumn field="approvers" />
          <AgGridColumn field="approvedOn" />
          <AgGridColumn field="comment" />
        </AgGridReact>
      </Wrapper>
    );
  }
}

export default withRouter(TimesheetDailySnapshot);
